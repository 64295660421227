html,
body {
  font-family: proxima-nova, sans-serif;
  overflow-x: hidden;
  position: relative;
}

.section-header {
  text-align: center;

  h1,
  h2 {
    font-size: 1.25rem;
    margin-bottom: 1.25rem;
    text-transform: uppercase;
  }

  .row {
    justify-content: center;
  }

  .col {
    flex: 0 0 80%;
    max-width: 80%;

    @include media-breakpoint-up(md) {
      flex: 0 0 60%;
      max-width: 60%;
    }

    @include media-breakpoint-up(lg) {
      flex: 0 0 40%;
      max-width: 40%;
    }
  }

  &__subtitle {
    font-weight: 300;
    font-size: 1.5rem;
    line-height: 2.1rem;
  }
}

label {
  margin-bottom: .5rem;
}

footer {
  background-color: $gray-100 !important;
  margin-top: 9rem;
  position: relative;

  @include media-breakpoint-down(xl) {
    .navbar-nav {
      padding-right: 48px;
    }
  }

  .footer {
    @include container;

    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 24px 15px;

    @include media-breakpoint-up(md) {
      .navbar-powered-by,
      .navbar-expand-lg {
        padding-top: 10px;
      }
    }

    @include media-breakpoint-down(md) {
      align-items: center;
      flex-direction: column;
      text-align: center;

      .navbar-powered-by {
        padding: 12px 0 16px;
      }

      .navbar-nav {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  h1,
  h2 {
    padding-top: 1rem;
    font-weight: 300;
  }
}

.card {
  background-color: white;
  border: 1px solid white;
  box-shadow: 0 2px 11px 0 rgb(127 127 136 / 13%);
  color: $text-dark;
  display: flex;
  flex-flow: column nowrap;
  font-family: proxima-nova, sans-serif;
  justify-content: space-between;
  transition: .25s ease all;

  &:hover {
    box-shadow: 2px 2px 13px 3px rgb(127 127 136 / 13%);
  }
}
