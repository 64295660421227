$spacer-navbar: 76px;

.body--has-navbar {
  padding-top: $spacer-navbar;
}

.navbar {
  background-color: white;
  box-shadow: 0 0 12px 0 transparent;
  padding: 1.5rem 1rem;
  transition-timing-function: ease;
  transition-duration: .3s;
  transition-property: padding;

  .scroll & {
    box-shadow: 0 0 12px 0 #d6e1f1;
    padding: .5rem 1rem;
  }

  .navbar-nav {
    text-transform: uppercase;
  }
}

.navbar-brand {
  img {
    height: 50px;
    width: auto;
  }
}

.navbar-container {
  @include container;
}

/**
 * Extra spacing to prevent the navbar from hiding the heading when
 * navigating to a heading anchor
 */
.heading-spacer {
  margin-top: -$spacer-navbar;
  padding-top: $spacer-navbar;
}
