html,
body {
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
}

h1,
h2,
h3 {
  font-weight: 300;
  padding-top: 3rem;
}
