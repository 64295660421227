@use "sass:map";

$lendesk-blue-dark: #0066a1;
$lendesk-blue: #0277bd;
$medium-gray: #495057;
$shadow: #c39421;
$text-dark: #0c1334;

// Extend theme colors
$theme-colors: map.merge(
  $theme-colors,
  (
    "primary": $lendesk-blue,
    "primary-dark": $lendesk-blue-dark,
    "shadow": $shadow,
    "white": white,
  )
);
$grid-breakpoints: map.merge(
  $grid-breakpoints,
  (
    "sm": 500px,
  )
);
