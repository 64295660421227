// Color Values
// --
// Note: Start with the common color name and if one already exists, add
// some sort of increment, e.g., $color-red-100. Don't worry too much about
// the increment as we'll be reworking the colors once design provides a
// color palette. Continue to review and consolidate where reasonable.
$lendesk-blue: #0277bd;
$lendesk-blue-dark: #0066a1;
$lendesk-blue-button: #1379bb;
$lendesk-gray-blue: #b5c7d3;
$lendesk-light-blue: #ebf4fa;
$lendesk-light-gray: #f5f7f8;
$spotlight-yellow: #fcc015;
$spotlight-orange-500: #f19c22;
$spotlight-orange-300: #ffac21;
$off-white: #fafafa;
$color-gray-100: #f5f5f5;
$color-gray-200: #e2e6ea;
$color-iron: #dcdee0;
$color-silver: #ccc;
$color-gray-400: #bfbfbf;
$color-gray-700: #333;
$error-red: #e11c1d;
$color-red-100: #d9534f;
$color-apple-blossom: #a94442;
$color-emerald: #00b8a9;
$color-gold: #f19c22;
$color-yellow: #fedd7e;
$color-green: #33c2a2;
$color-lochmara: #0978ce;
$color-blue-700: #01578a;
$color-blue-600: 128 189 255;
$color-blue-500: #499dcf;
$color-blue-300: #c2deef;
$color-blue-200: #d7e9f4;
$color-purple: #6457a6;
$color-purple-light: #e6e4f1;
$color-tradewind: #4fb5a9;
$padding-base-vertical: 6px !default;
$padding-base-horizontal: 12px !default;
$heading-gray: #c3c5cd;
$tia-maria: #db3814;

// Borders
$border-radius-base: 3px;
$laravel-border-color: #e5e6e9 #dfe0e4 #d0d1d5;
$list-group-border: $laravel-border-color;
$navbar-default-border: #fff;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// SideNav
$sidenav-padding: 20px;
$sidebar-width: 320px;
$sidebar-width-md: 250px;

// Typography
$font-family-sans-serif: "proxima-nova", "helvetica neue", helvetica, sans-serif;
$font-size-base: 14px;
